<template>
  <v-form>
    <v-card-title class="modal-cadastro-titulo">
      Anexos de documentos - {{ item.jsonData.fullName }}
    </v-card-title>
    <v-card-text class="modal-cadastro-form">
      <v-row>
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-file-input
            label="Documento"
            prepend-icon="mdi-camera"
            v-model="image" />
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
          <v-text-field v-model="item.description" label="Descrição:" />
        </v-col>
      </v-row>
    </v-card-text>
    <div>
      <v-container>
        <div>
          <v-skeleton-loader
          :loading="loadingTable"
          :transition="'fade-transition'"
          height="150px"
          type="table">
            <v-data-table
            :headers="headers"
            :items="listDocuments"
            flat>
              <template v-slot:item.fileName="{ item }">
                {{ item.document.fileName }}
              </template>
              <template v-slot:item.description="{ item }">
                {{ item.document.description }}
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn tile
                outlined 
                :color="variables.colorError"
                @click="deleteUserDocuments(item)"
                >
                  Deletar
                </v-btn>
              </template>
            </v-data-table>
          </v-skeleton-loader>
        </div>
      </v-container>
    </div>
    <v-card-actions class="modal-cadastro-footer">
      <v-btn :color="variables.colorPrimary" class="br-btn br-btn-cancelar">Cancelar</v-btn>
      <v-btn type="submit" :color="variables.colorPrimary"  @click.prevent="submit" class="br-btn" :loading="loading" v-if="permiteAcao({ path: '/consultas/usuarios-web' }, 'edit')">Salvar</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
// eslint-disable-next-line
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'

import validate from './validate'

export default {
  name: 'usuarioWebPremios',
  mixins: [validationMixin],
  directives: {
    mask
  },
  data: () => ({
    headers: [
      { align: 'start', class: 'table-header', text: 'Nome do arquivo', value: 'fileName' },
      { align: 'start', class: 'table-header', text: 'Descrição', value: 'description' },
      { align: 'end', class: 'table-header', text: '', value: 'action' }
    ],
    loading: false,
    image: [],
  }),

  watch: {
    'image' (val) {
      this.addImage(val)
    },
  },

  computed: {
    ...mapGetters('usuariosWeb', {item: 'item', totalItens: 'totalItens', loadingTable: 'loadingTable', listDocuments: 'listDocuments'}),
    ...mapGetters('roles', ['permiteAcao']),
    
    ...validate,
    
    variables: () => variables,
  },
  methods: {
    ...mapActions('usuariosWeb', ['getItens', 'editarItem', 'deletarItem', 'clearItens', 'getItem', 'limparItem', 'editSaveItem', 'createDocuments', 'getDocuments', 'deleteDocuments', 'setLoadingTable']),

    addImage (e) {
      if (!e) {
        this.item.image = ''
        return
      }
      if (!e.type.match('image.*') && !e.type.match('application.*')) {
        this.item.image = ''
        return
      }
      this.filename = e.name
      const reader = new FileReader();
      reader.onload = (e) => this.item.image = e.target.result;
      reader.readAsDataURL(e);
    },

    submit () {
      const self = this

      // if (self.$v.$invalid) {
      //   self.$v.item.$touch()
      //   return false 
      // }

      this.loading = true

      const dados = {
        entityId: this.item.id,
        document: this.item.image,
        description: this.item.description,
        fileName: self.filename
      }
    
      self.usuarioEditSave(dados)
    },

    usuarioEditSave (val) {
      const self = this
      self.createDocuments(val).then(() => {
        self.$emit('closeModal')
        self.loading = false
        self.getItens()
        Events.$emit('snackbarCadastro::msg', {
          toggle: true,
          type: 'success',
          msg: 'Edição realizado com sucesso!'
        })
      }).catch(err => {
        self.loading = false
        Events.$emit('snackbarCadastro::msg', {
          toggle: true,
          type: 'error',
          msg: err.error
        })
      })
    },
    deleteUserDocuments (item) {
    let self = this
    self.deleteDocuments(item).then((result) => {
      if (result) {
        self.setLoadingTable(true)
        self.getDocuments(self.item).then(() => {
          self.setLoadingTable(false)
        })
      }
    })
  },
  },

  validations () {
    return {
      item: {
        description: { required },
        documents: { required }
      }
    }
  }
}
</script>
